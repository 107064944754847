.th-custom {
  text-transform: capitalize !important;
  font-size: 14px !important;
  font-weight: 400;
}

.bb-0 {
  border-bottom: 0px !important;
}

.empty-table {
  height: 400px;
  padding-top: 100px;
}

.small-empty-table {
  height: 220px;
  padding-top: 30px;
}

.medium-empty-table {
  height: 350px;
  padding-top: 100px;
}

.mh-400 {
  max-height: 400px;
  overflow-x: hidden;
}

.mh-600 {
  max-height: 600px;
  overflow-x: hidden;
}

.mh-700 {
  max-height: 700px;
  overflow-x: hidden;
}

.bg-grey {
  background: rgba(0, 62, 107, .05);
}

.c-green {
  color: #28a745 !important;
}