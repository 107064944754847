.empty-card {
  height: 600px;
  padding-top: 200px;
}

.test {
  padding-right: 15px;
}

.br-grey {
  border-right: 2px solid rgba(16, 42, 67, .1);
}
